import { useBaseUrl } from '@/composables/useBaseUrl'
import axios from 'axios'
import { useLocale } from '@/composables/useLocale'
import { useAlerts } from '@/composables/useAlerts'
import { AlertType } from '@/enums/alertType'
import { router } from '@/router'
import { useAuth } from '@/composables/useAuth'

import i18n from '@/plugins/i18n-plugin'

import { useCookies } from '@/composables/api/useCookies'
import { useModel } from '@/composables/api/useModel'

const { addAlert, removeDuplicateAlerts, removeAlertAfterDelay } = useAlerts()
const { clearAllCookies, setCookie } = useCookies()
const globalAxiosInstance = axios.create({
  baseURL: useBaseUrl().baseUrl,
  timeout: 120_000,
  withCredentials: true,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
  withXSRFToken: true,
})

globalAxiosInstance.interceptors.request.use(
  function (config) {
    config.headers['Accept-Language'] = useLocale().getLocale.value
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

globalAxiosInstance.interceptors.response.use(
  function (response) {
    removeDuplicateAlerts()
    removeAlertAfterDelay(0)
    // Any status code that lie within the range of 2xx cause this function to trigger
    const responseAlertMessage = response?.data?.message
    if (responseAlertMessage) {
      addAlert(responseAlertMessage, AlertType.Success)
    }

    const setCookieHeader = response.headers['set-cookie']
    if (setCookieHeader) {
      // Clear all existing cookies
      clearAllCookies()

      // Set the new cookies from the response
      setCookieHeader.forEach((cookieString) => {
        const cookieName = cookieString.split('=')[0]
        setCookie(cookieName, cookieString)
      })
    }

    return response
  },
  function (error) {
    const { processing, processTerminated } = useModel()
    if (processing.value) {
      processTerminated()
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    console.log({ error })

    const code = parseInt(error.response && error.response.status)

    if (code === 401) {
      addAlert(i18n.global.t('session_expired'), AlertType.Information)

      useAuth().logout()

      return
    }

    if (code === 422 && error.response.data.errors.email) {
      setTimeout(() => {
        router.push(`/${useLocale().getLocale.value}/auth/login`)
      }, 3000)
    }

    if (error?.response?.data?.errors) {
      console.log('has errors')

      if (processing.value) {
        processTerminated()
      }
      const errors = []
      Object.entries(error?.response?.data?.errors).forEach((errorsWithField) =>
        // @ts-ignore
        errorsWithField[1].forEach((error) => errors.push(error))
      )

      errors.map((error) => addAlert(error, AlertType.Danger))

      return
    }

    const shouldVerifyEmail = error?.response?.data?.should_verify_email

    if (shouldVerifyEmail) {
      router.push({
        name: 'login',
        query: {
          validationMail: 1,
        },
      })
    }

    if (error?.response?.data?.message) {
      addAlert(error?.response?.data?.message, AlertType.Danger)
      return
    }

    addAlert(
      i18n.global.t('unexpected_server_error_occurred'),
      AlertType.Danger
    )

    return Promise.reject(error)
  }
)

export const useAxios = () => {
  return { axios: globalAxiosInstance }
}
