import Ajv from 'ajv/dist/2020'
import addAjvErrors from 'ajv-errors'
// @ts-ignore
import addFormats from 'ajv-formats'

export const useApiJsonValidator = () => {
  const validateData = (schema, data) => {
    const ajv = new Ajv({ allowUnionTypes: true, allErrors: true })
    addAjvErrors(ajv)
    addFormats(ajv)

    const validate = ajv.compile(schema)

    const valid = validate(data)

    if (!valid) {
      console.log('Validation errors:')
      console.log(validate.errors)
      validate.errors?.forEach((error) => {
        console.log(error.message)
      })
      return false
    } else {
      return true
    }
  }

  return { validateData }
}
