// @ts-ignore
const baseUrl = import.meta.env.DEV
  ? // @ts-ignore
    import.meta.env.VITE_LOCAL_API_BASE_URL
  : // @ts-ignore
    import.meta.env.VITE_API_BASE_URL

export const useBaseUrl = () => {
  return {
    baseUrl,
  }
}
