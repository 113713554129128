import { useAxios } from '@/composables/api/useAxios'

const { axios } = useAxios()

export const useRepositoryApi = (
  resource,
  overrideRoutes = null,
  additionalFunctions = {}
) => {
  const index = async (
    page: number = 1,
    simple: boolean = false,
    id = null,
    queryParams = {}
  ) => {
    const basePath =
      overrideRoutes != null && !!overrideRoutes?.index
        ? `/api/${overrideRoutes.index(id)}`
        : `/api/${resource}`

    queryParams = { ...queryParams, page }

    if (simple) {
      // @ts-ignore
      queryParams.simple = true
    }

    const queryString = new URLSearchParams(queryParams).toString()
    const fullPath = `${basePath}?${queryString}`

    return (await axios.get(fullPath))?.data
  }

  const show = async (id: string) => {
    return (
      await axios.get(
        overrideRoutes != null && !!overrideRoutes?.show
          ? `/api/${overrideRoutes.show(id)}`
          : `/api/${resource}/${id}`
      )
    )?.data
  }

  const create = async (payload: object | string[] | Object[]) => {
    return (await axios.post(`/api/${resource}`, payload))?.data
  }

  const update = async (id: string, payload: object | string[] | Object[]) => {
    return (await axios.post(`/api/${resource}/${id}`, payload))?.data
  }

  const patch = async (id: string, payload: object | string[] | Object[]) => {
    return (await axios.patch(`/api/${resource}/${id}`, payload))?.data
  }

  const deleteFromId = async (id: string) => {
    return (await axios.delete(`/api/${resource}/${id}`))?.data
  }

  const renameFromId = async (id: string, title: string) => {
    return await axios.put(`/api/${resource}/rename/${id}`, {
      title: title,
    })
  }

  return {
    index,
    show,
    create,
    update,
    patch,
    deleteFromId,
    additionalFunctions,
    renameFromId,
  }
}
