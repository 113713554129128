import { useUserRepositoryApi } from '@/composables/api/useUserRepositoryApi'
import { useRepositoryApi } from '@/composables/api/useRepositoryApi'
import { useExecutableModelsRepository } from '@/composables/api/useExecutableModelsRepository'
import { useOAuthProviderRepository } from '@/composables/api/useOAuthProviderRepository'

export const useApiRepositories = () => {
  const { createUserRepository } = useUserRepositoryApi()

  const repositories = {
    apiKeysRepository: useRepositoryApi('tokens'),
    QueriesRepository: useRepositoryApi('query-usages'),
    modelsUsageRepository: useRepositoryApi('usages'),
    modelsRepository: useRepositoryApi('phedone-models'),
    modelsExecutableRepository: useExecutableModelsRepository(),
    integrationsRepository: useRepositoryApi('phedone-integrations'),
    userRepository: createUserRepository(),
    currentPeriodUsageRepository: useRepositoryApi('current-period-usage'),
    subscriptionsRepository: useRepositoryApi('subscriptions'),
    billingRepository: useRepositoryApi('billing'),
    bucketsRepository: useRepositoryApi('buckets'),
    dashboardsRepository: useRepositoryApi('buckets/dashboards', {
      show: (id) => `buckets/${id}/dashboard`,
    }),
    oAuthProviders: useOAuthProviderRepository(),
  }

  return { ...repositories }
}
