import { useApiRepositories } from '@/composables/api/useApiRepositories'
import { ref, computed, watch } from 'vue'
import { useDateUtils } from '@/composables/useDateUtils'
import { useApiJsonValidator } from '@/composables/useApiJsonValidator'
import bucketsValidator from '@/jsonValidator/bucketsValidator.json'
import { BucketsInterface } from '@/interfaces/BucketsInterface'
import i18n from '@/plugins/i18n-plugin'
import { useLocale } from '@/composables/useLocale'

const currentSelectedBucket = ref<string>('')
const currentSelectedBucketId = ref<string | null>(null)
const openSuccessModal = ref<boolean>(false)
const openBucketsSelectorModal = ref<boolean>(false)
const loadingBuckets = ref<boolean>(false)

const buckets = ref<BucketsInterface[] | null>(null)
const bucketsWithMeta = ref(null)
const generatedBucketName = ref<string>('')
export const useBuckets = () => {
  const { validateData } = useApiJsonValidator()
  const { locale } = useLocale()

  const { dashboardsRepository, bucketsRepository } = useApiRepositories()
  const { formatDateToShortString, timestampToDate } = useDateUtils()
  const onCreate = ref(false)

  const clearBuckets = () => {
    buckets.value = null
  }

  const refreshBuckets = async (page = 1) => {
    await getBuckets(page)
  }

  const clearAndRefreshBuckets = async (page = 1) => {
    clearBuckets()
    await getBuckets(page)
  }

  const getBuckets = async (page = 1) => {
    try {
      if (buckets.value) {
        return
      }
      loadingBuckets.value = true

      const response = await dashboardsRepository.index(page, true)
      bucketsWithMeta.value = response

      const isValidData = validateData(bucketsValidator, response.data)
      if (isValidData) {
        buckets.value = response.data
      }
      loadingBuckets.value = false
    } catch (error) {
      console.log('Unable to get buckets', error)
    }
  }

  const formattedBuckets = computed(() => {
    if (buckets.value && buckets.value && buckets.value.length > 0) {
      return buckets.value.filter(Boolean).map((k) => ({
        ...k,
        ...(k && {
          metrics: {
            id: k.id,
            maj: formatDateToShortString(timestampToDate(k.last_updated_at)),
            entries: k.results.count ? k.results.count : 0,
            models: k.results.models.filter(Boolean).map((m) => ({
              title: m.name,
              path: `models/${m.slug}`,
            })),
          },
        }),
      }))
    }
    return []
  })

  const formattedBucketsForBox = computed(() => {
    const defaultBucket = { label: i18n.global.t('No save results'), value: '' }

    if (buckets.value) {
      const bucketsBox = buckets.value.map((bucket) => ({
        label: bucket.title,
        value: bucket.id,
      }))
      return [defaultBucket, ...bucketsBox]
    }
    return []
  })

  const createBucket = async (
    bucketTitle: string,
    webhookEnabled: boolean = false,
    webhookSigned: boolean = false
  ) => {
    try {
      onCreate.value = true
      const bucket = await bucketsRepository.create({
        title: bucketTitle,
        webhooks_enabled: webhookEnabled,
        webhooks_signing_enabled: webhookEnabled && webhookSigned,
      })

      if (bucket) {
        generatedBucketName.value = bucketTitle
        openSuccessModal.value = true
        clearBuckets()
        await refreshBuckets()
        // @ts-ignore
        currentSelectedBucket.value = formattedBucketsForBox.value[1]

        onCreate.value = false
        return true
      }
    } catch (error) {
      console.log('unable to create bucket', error)
    }
    return false
  }

  const closeSelectorModal = () => {
    openBucketsSelectorModal.value = false
  }

  watch(locale, () => {
    clearBuckets()
  })

  const findBucketForBoxById = (selectedBucketId) => {
    return formattedBucketsForBox.value.find(
      (bucket) => bucket.value === selectedBucketId
    )
  }

  return {
    getBuckets,
    refreshBuckets,
    buckets,
    formattedBuckets,
    openSuccessModal,
    generatedBucketName,
    createBucket,
    onCreate,
    currentSelectedBucket,
    formattedBucketsForBox,
    bucketsWithMeta,
    loadingBuckets,
    openBucketsSelectorModal,
    closeSelectorModal,
    currentSelectedBucketId,
    clearBuckets,
    clearAndRefreshBuckets,
    findBucketForBoxById,
  }
}
