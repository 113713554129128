import * as Fathom from 'fathom-client'

export const useFathom = () => {
  const load = () => {
    // @ts-ignore
    Fathom.load(import.meta.env.VITE_FATHOM_ID)
  }

  const fathomOnSignUpEvent = () => {
    Fathom.trackEvent('Auth.Sign_Up')
  }

  const fathomOnSignInEvent = () => {
    Fathom.trackEvent('Auth.Sign_In')
  }

  const fathomOnSignInGoogleEvent = () => {
    Fathom.trackEvent('Auth.Sign_In.Google')
  }

  const fathomOnSignInGithubEvent = () => {
    Fathom.trackEvent('Auth.Sign_In.Github')
  }

  const fathomModelsPageEvent = () => {
    Fathom.trackEvent('Models.Page_Viewed.Index')
  }

  const fathomInsightPageEvent = () => {
    Fathom.trackEvent('Insights.Page_Viewed.Detailed')
  }

  const fathomModelPageEvent = (slug: string) => {
    Fathom.trackEvent(`Models.Page_Viewed.${slug}`)
  }

  const fathomTrackPlanEvent = (priceInCents: number) => {
    Fathom.trackEvent('Plans.Subscription_Clicked.Index', {
      _value: priceInCents / 100,
    })
  }

  const fathomTrackPlanClickedEvent = (slug: string) => {
    Fathom.trackEvent(`Plans.Subscription_Clicked.${slug}`)
  }

  const fathomTrackEnterpriseClickedEvent = () => {
    Fathom.trackEvent('Plans.Enterprise_Clicked')
  }

  const fathomModelsClickEvent = (slug: string) => {
    Fathom.trackEvent(`Models.Card_Clicked.${slug}`)
  }

  const fathomModelsClickPaletteEvent = (slug: string) => {
    Fathom.trackEvent(`Models.Command_Palette_Clicked.${slug}`)
  }

  const fathomIntegrationClickEvent = (title: string) => {
    Fathom.trackEvent(`Integrations.Integration_Clicked.${title}`)
  }

  const fathomModelApiDocumentationClicked = (slug: string) => {
    Fathom.trackEvent(`Models.Model_API_Doc_Clicked.${slug}`)
  }

  const fathomModelApiDocumentation = (slug: string) => {
    Fathom.trackEvent(`Models.Model_API_Doc_Public_Clicked.${slug}`)
  }
  const fathomIntegrationsApiDocumentation = () => {
    Fathom.trackEvent(`Integrations.API_Doc_Public_Clicked`)
  }

  const fathomRedirectionDiabolocomLandingPage = () => {
    Fathom.trackEvent(`Redirection.Landing_Page_Clicked`)
  }

  const fathomSubscriptionsParametersClicked = () => {
    Fathom.trackEvent(`Plans.Subscription_Parameters_Clicked`)
  }

  return {
    fathomOnSignUpEvent,
    load,
    fathomModelsPageEvent,
    fathomOnSignInEvent,
    fathomOnSignInGoogleEvent,
    fathomTrackPlanEvent,
    fathomModelsClickEvent,
    fathomIntegrationClickEvent,
    fathomModelPageEvent,
    fathomModelsClickPaletteEvent,
    fathomModelApiDocumentation,
    fathomOnSignInGithubEvent,
    fathomInsightPageEvent,
    fathomModelApiDocumentationClicked,
    fathomIntegrationsApiDocumentation,
    fathomRedirectionDiabolocomLandingPage,
    fathomTrackPlanClickedEvent,
    fathomTrackEnterpriseClickedEvent,
    fathomSubscriptionsParametersClicked,
  }
}
