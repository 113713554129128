import { useAxios } from '@/composables/api/useAxios'
import { UserData } from '@/interfaces/UserDataInterface'
import { useXsrfToken } from '@/composables/api/useXsrfToken'
const { axios } = useAxios()

export const useUserRepositoryApi = () => {
  const { getFreshToken } = useXsrfToken()

  const register = async (registerData: UserData, referrer) => {
    await getFreshToken()
    return axios.post(`/api/register`, {
      email: registerData.email,
      name: registerData.name,
      last_name: registerData.last_name,
      first_name: registerData.first_name,
      password: registerData.password,
      corporation: registerData.corporation,
      phone: registerData.phone,
      referrer,
    })
  }

  const login = async (email: string, password: string) => {
    await getFreshToken()
    return axios.post(`/api/login`, {
      email: email,
      password: password,
    })
  }

  const fetchUser = async () => {
    return (await axios.get('/api/user'))?.data
  }

  const deleteAccount = async () => {
    return await axios.delete('/api/user')
  }

  const resendEmailVerification = async (email: string) => {
    await getFreshToken()
    return axios.post(`/api/email/verification-notification`, { email })
  }

  const update = async (updateData: UserData) => {
    return await axios.put(`/api/user/profile-information`, {
      email: updateData.email,
      corporation: updateData.corporation,
      first_name: updateData.first_name,
      last_name: updateData.last_name,
      phone: updateData.phone,
    })
  }

  const updatePassword = async (
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
  ) => {
    return await axios.put(`/api/user/password`, {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: newPasswordConfirmation,
    })
  }

  const confirmPassword = async (password: string) => {
    return await axios.post(`/api/user/confirm-password`, {
      password,
    })
  }

  const getConfirmedPasswordStatus = async () => {
    return axios.get(`/api/user/confirmed-password-status`)
  }

  const forgetPassword = async (email: string) => {
    await getFreshToken()
    return axios.post(`/api/forgot-password`, {
      email,
    })
  }

  const resetPassword = async (userData: UserData, token) => {
    await getFreshToken()
    return await axios.post(`/api/reset-password`, {
      password: userData.password,
      email: userData.email,
      password_confirmation: userData.confirmPassword,
      token,
    })
  }

  const verifyEmail = async (expires, signature, id, hash) => {
    return await axios.get(
      `/api/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`
    )
  }

  const logout = async () => {
    return axios.post(`/api/logout`)
  }

  const createUserRepository = () => {
    return {
      register,
      resendEmailVerification,
      update,
      updatePassword,
      confirmPassword,
      getConfirmedPasswordStatus,
      forgetPassword,
      resetPassword,
      verifyEmail,
      login,
      fetchUser,
      deleteAccount,
      logout,
    }
  }

  return {
    createUserRepository,
  }
}
