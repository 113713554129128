import { ref } from 'vue'

const isSelected = ref(null)
const displayMainMenu = ref(false)
export const useActiveMenu = () => {
  const handleActiveSelectionMenu = (to: string) => {
    switch (to) {
      case 'profile':
        isSelected.value = null
        break
      case 'home':
        isSelected.value = {
          id: 'home',
        }
        break
      case 'insights':
        isSelected.value = {
          id: 'insights',
        }
        break
      case 'developer':
        isSelected.value = {
          id: 'developer',
        }
        break
      case 'models':
        isSelected.value = {
          id: 'models',
        }
        break
      default:
        isSelected.value = null
        break
    }
  }

  return { isSelected, handleActiveSelectionMenu, displayMainMenu }
}
