import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  legacy: false,
  locale: 'fr', // Default locale
  messages: {
    fr: {
      session_expired: 'Votre session a expirée, merci de vous reconnecter',
      unexpected_server_error_occurred: 'Une erreur inattendue est survenue',
      'No save results': 'Ne pas stocker les résultats',
      ony_MP3_and_WAV:
        'Seuls les fichiers MP3 et WAV de moins de 20 Mo sont acceptés.',
      'Deactivated account':
        "Votre compte est désactivé, souscrivez à une offre pour continuer d'utiliser nos services.",
      day: 'jour',
      days: 'jours',
      weeks: 'semaines',
      Insight: 'Tableau de bord',
      Insights: 'Rapports et analyses',
      Developer: 'Pour les développeurs',
      Models: 'Modèles',
      Account: 'Mon compte',
      Model: 'modèle',
      Login: 'Connexion',
      Register: 'Création de compte',
      Reset: 'Réinitialiser mot de passe',
      Home: 'Accueil',
      Verification: 'Vérification',
    },
    en: {
      session_expired: 'Your session has expired, please log in again',
      unexpected_server_error_occurred: 'An unexpected server error occurred',
      'No save results': 'Do not save results',
      ony_MP3_and_WAV: 'Only MP3 and WAV files under 20MB are accepted.',
      'Deactivated account':
        'Your account is disabled, subscribe to a plan to keep using our service.',
      day: 'day',
      days: 'days',
      weeks: 'weeks',
      Insight: 'Dashboard',
      Insights: 'Reports and analyses',
      Developer: 'For developers',
      Models: 'Models',
      Model: 'Model',
      Account: 'My account',
      Login: 'Login',
      Register: 'Sign up',
      Reset: 'Reset Password',
      Home: 'Home',
      Verification: 'Verification',
    },
    de: {
      session_expired:
        'Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an',
      unexpected_server_error_occurred:
        'Ein unerwarteter Serverfehler ist aufgetreten',
      'No save results': 'Ergebnisse nicht speichern',
      ony_MP3_and_WAV: 'Nur MP3- und WAV-Dateien unter 20 MB sind zulässig.',
      'Deactivated account':
        'Your account is disabled, subscribe to a plan to keep using our service.',
    },
    es: {
      session_expired:
        'Su sesión ha caducado, por favor inicie sesión nuevamente',
      unexpected_server_error_occurred:
        'Se ha producido un error inesperado del servidor',
      'No save results': 'No guardar resultados',
      ony_MP3_and_WAV: 'Solo se aceptan archivos MP3 y WAV de menos de 20 MB.',
      'Deactivated account':
        'Your account is disabled, subscribe to a plan to keep using our service.',
    },
    it: {
      session_expired:
        "La tua sessione è scaduta, effettua nuovamente l'accesso",
      unexpected_server_error_occurred:
        'Si è verificato un errore imprevisto del server',
      'No save results': 'Non salvare i risultati',
      ony_MP3_and_WAV:
        'Sono accettati solo file MP3 e WAV di dimensioni inferiori a 20 MB.',
      'Deactivated account':
        'Your account is disabled, subscribe to a plan to keep using our service.',
    },
  },

  fallbackRoot: false,
  missingWarn: false,
})

export default i18n
