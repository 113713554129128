import { computed, ref } from 'vue'
import { useAxios } from '@/composables/api/useAxios'
import Cookies from 'js-cookie'

const globalToken = ref(Cookies.get('XSRF-TOKEN'))

export const useXsrfToken = () => {
  const getFreshToken = async () => {
    const { axios } = useAxios()

    // We fetch the XSRF Cookie, nothing is return, we could eventually store the returning cookie if necessary
    await axios.get('/api/csrf-cookie')
  }

  const token = computed(
    () =>
      (globalToken.value &&
        globalToken.value.length > 0 &&
        globalToken.value) ||
      Cookies.get('XSRF-TOKEN')
  )

  return { getFreshToken, token, globalToken }
}
