import { ref, computed } from 'vue'
import { useAxios } from '~/composables/api/useAxios'

const availablesTranscriptLanguages = ref(null)
const availablesInsightsLanguages = ref(null)

export const useLlmLanguages = () => {
  const { axios } = useAxios()

  const clearAvailablesLanguages = () => {
    availablesTranscriptLanguages.value = null
    availablesInsightsLanguages.value = null
  }

  const fetchTranscriptLanguages = async () => {
    try {
      if (availablesTranscriptLanguages.value) {
        return
      }
      const response = await axios.get('/api/locales/transcription-input')
      const result = response.data

      if (result) {
        availablesTranscriptLanguages.value = result
      }
    } catch (error) {
      console.log('Unable to fetch transcript languages', error)
    }
  }

  const formattedTranscriptLanguages = computed(() => {
    if (!availablesTranscriptLanguages.value) {
      return []
    }
    return Object.keys(availablesTranscriptLanguages.value).map((key) => ({
      label: availablesTranscriptLanguages.value[key].display_name,
      value: key,
    }))
  })

  const fetchInsightsLanguages = async () => {
    try {
      if (availablesInsightsLanguages.value) {
        return
      }
      const response = await axios.get('/api/locales/insights-output')
      const result = response.data
      if (result) {
        availablesInsightsLanguages.value = result
      }
    } catch (error) {
      console.log('Unable to fetch insights languages', error)
    }
  }

  const formattedInsightsLanguages = computed(() => {
    if (!availablesInsightsLanguages.value) {
      return []
    }
    return Object.keys(availablesInsightsLanguages.value).map((key) => ({
      label: availablesInsightsLanguages.value[key].display_name,
      value: key,
    }))
  })

  return {
    fetchTranscriptLanguages,
    fetchInsightsLanguages,
    formattedTranscriptLanguages,
    formattedInsightsLanguages,
    clearAvailablesLanguages,
  }
}
