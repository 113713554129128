import { ref } from 'vue'
const deleteBucketModal = ref<boolean>(false)
const valueToDeleteObject = ref<{}>({})
const bucketTitleModal = ref<boolean>(false)
const bucketTitleToUpdate = ref<{}>({})

export const useModal = () => {
  const openDeleteModal = () => {
    deleteBucketModal.value = true
  }

  const openBucketTitleModal = () => {
    bucketTitleModal.value = true
  }

  const clearDeleteModal = () => {
    deleteBucketModal.value = false
  }

  return {
    deleteBucketModal,
    openDeleteModal,
    valueToDeleteObject,
    clearDeleteModal,
    bucketTitleModal,
    bucketTitleToUpdate,
    openBucketTitleModal,
  }
}
