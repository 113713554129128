export const useStringUtils = () => {
  const plainStringToPhone = (plainPhoneStringNumber) =>
    plainPhoneStringNumber.match(/[\s\S]{1,2}/g || []).join(' ')

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1)

  const replaceHyphen = (string) => string.replace(/^-/, '')

  const cleanOfUnicode = (jsonString) =>
    jsonString.replace(/u([0-9a-fA-F]{4})/g, (_, code) => {
      return String.fromCharCode(parseInt(code, 16))
    })

  const isArray = (value) => {
    return Array.isArray(value)
  }

  return {
    plainStringToPhone,
    capitalizeFirstLetter,
    replaceHyphen,
    cleanOfUnicode,
    isArray,
  }
}
