import { createApp } from 'vue'
import pinia from './plugins/pinia-persist.js'
import Vue3EasyDataTable from './plugins/vue3-datatable.js'
import i18n from './plugins/i18n-plugin.js'

import './style.css'
import './../src/assets/css/components.css'
import App from './App.vue'
import axios from 'axios'
import { router } from './router'
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { AgChartsVue } from 'ag-charts-vue3'
import { TagsInput } from '@nor1c/vue-tagsinput'
import vue3GoogleLogin from 'vue3-google-login'
import 'highlight.js/lib/common'
// @ts-ignore
import hljsVuePlugin from '@highlightjs/vue-plugin'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'
import { createHead } from '@unhead/vue'
const head = createHead()

// @ts-ignore
axios.defaults.withCredentials = !import.meta.env.DEV
axios.defaults.withXSRFToken = true
axios.defaults.xsrfCookieName = 'XSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN'

createApp(App)
  .use(router)
  .use(pinia)
  .use(i18n)
  .use(head)
  .use(hljsVuePlugin)
  .use(VueTelInput)
  .use(vue3GoogleLogin, {
    // @ts-ignore
    clientId: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
  })
  .component('EasyDataTable', Vue3EasyDataTable)
  .component('PrismEditor', PrismEditor)
  .component('ag-charts-vue', AgChartsVue)
  .component('tags-input', TagsInput)
  .mount('#app')
