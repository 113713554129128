import { ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
// @ts-ignore
import frenchFlag from '@/assets/img/svg/flags/french_flag.svg'
// @ts-ignore
import enFlag from '@/assets/img/svg/flags/en_flag.svg'
// @ts-ignore
// import deFlag from '@/assets/img/svg/flags/de_flag.svg'
// @ts-ignore
// import spFlag from '@/assets/img/svg/flags/spain_flag.svg'
// @ts-ignore
// import itFlag from '@/assets/img/svg/flags/it_flag.svg'

import { useModels } from '~/composables/api/useModels'
import { useRoute } from 'vue-router'
import { router } from '~/router'
import { useLlmLanguages } from '~/composables/api/useLlmLanguages'

const locale = ref('fr')
const currentTab = ref(null)
export const useLocale = (i18n = false) => {
  const { refreshModels } = useModels()
  let setLocale = null

  if (i18n) {
    const i18n = useI18n()

    setLocale = (newLocale) => {
      locale.value = newLocale
      i18n.locale.value = newLocale
    }
  }
  const getLabelFromLocale = (locale: string) => {
    const languageObject = localeItems.find((lang) => lang.value === locale)

    return languageObject ? languageObject.label : null
  }

  const getIconFromLocale = (locale: string) => {
    const languageObject = localeItems.find((lang) => lang.value === locale)

    return languageObject ? languageObject.icon : null
  }
  const route = useRoute()

  const refreshPage = () => {
    if (route) {
      let currentUrl = ref(route.fullPath)
      const langRegex = /^\/[a-zA-Z]{2}\//
      currentUrl.value = currentUrl.value.replace(
        langRegex,
        '/' + locale.value + '/'
      )
      router.push({
        path: currentUrl.value,
        query: currentTab.value ? { tab: currentTab.value } : route.query,
      })
    }
  }

  const { clearAvailablesLanguages } = useLlmLanguages()
  watch(locale, () => {
    clearAvailablesLanguages()
    refreshModels()
  })

  const getLocale = computed(() => locale.value)

  const localeItems = [
    { label: 'Français', value: 'fr', icon: frenchFlag },
    { label: 'English', value: 'en', icon: enFlag },
    // { label: 'Deutsch', value: 'de', icon: deFlag, disabled: true },
    // { label: 'Español', value: 'es', icon: spFlag, disabled: true },
    // { label: 'Italiano', value: 'it', icon: itFlag, disabled: true },
  ]

  return {
    locale,
    setLocale,
    localeItems,
    getLabelFromLocale,
    getIconFromLocale,
    getLocale,
    refreshPage,
    currentTab,
  }
}
