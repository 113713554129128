import { useUserStore } from '@/store/useUserStore'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { FeatureTogglesEnums } from '~/enums/featureTogglesEnums'

export const useFeatureToggles = () => {
  const store = useUserStore()
  const { featureToggles } = storeToRefs(store)

  const haveLimitedProfileAccess = computed(() =>
    (featureToggles.value || []).includes(FeatureTogglesEnums.ResultsAccessOnly)
  )

  const haveNoUsageVisible = computed(() =>
    (featureToggles.value || []).includes(FeatureTogglesEnums.ResultsAccessOnly)
  )

  const haveInsightsAccessOnly = computed(() =>
    (featureToggles.value || []).includes(FeatureTogglesEnums.ResultsAccessOnly)
  )

  return {
    haveLimitedProfileAccess,
    haveNoUsageVisible,
    haveInsightsAccessOnly,
  }
}
