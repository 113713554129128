import humanizeDuration from 'humanize-duration'
import { useLocale } from '@/composables/useLocale'
import i18n from '@/plugins/i18n-plugin'

export const useDateUtils = () => {
  const humanizeDifferenceFromDateToNow = (date: any): string | null => {
    if (date === null) return null

    if (typeof date === 'string') {
      date = new Date(date)
    }

    if (date === new Date()) {
      return 'quelques instants'
    }

    return humanizeDuration(new Date().getTime() - date.getTime(), {
      language: 'fr',
      largest: 1,
      units: ['mo', 'w', 'd', 'h', 'm', 's'],
      round: true,
    })
  }

  const dateDiffInDays = (a: Date | string, b: Date | string) => {
    if (a === null) return null

    if (typeof a === 'string') {
      a = new Date(a)
    }

    if (b === null) return null

    if (typeof b === 'string') {
      b = new Date(b)
    }

    const _MS_PER_DAY = 1000 * 60 * 60 * 24
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

    return Math.floor((utc2 - utc1) / _MS_PER_DAY)
  }

  const durationFromOrDateIfTooFar = (date: Date, simple: boolean = false) => {
    const dateDiff = dateDiffInDays(date, new Date())

    if (dateDiff === 0 && !simple) {
      return "Aujourd'hui"
    }

    if (dateDiff === 1) {
      return 'Hier'
    }

    if (dateDiff === 2) {
      return 'Avant-hier'
    }

    if (simple) {
      return (
        `${dateDiff === 0 ? 'Il y a ' : ''}` +
        humanizeDifferenceFromDateToNow(date)
      )
    }

    return date.toLocaleDateString('fr-FR', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    })
  }

  const { locale } = useLocale()
  const formatDate = (value, withTime = false) => {
    value = new Date(value)

    return value.toLocaleString(locale.value, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      ...(withTime && {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }),
    })
  }

  const timestampToDate = (timestamp) => {
    return new Date(timestamp * 1e3)
  }

  const daysBeforeReset = (endDate: number) => {
    const now = new Date()
    const periodEnd = new Date(timestampToDate(endDate))

    const differenceMillis = periodEnd.getTime() - now.getTime()

    return Math.ceil(differenceMillis / (1000 * 60 * 60 * 24))
  }

  const durationToNowWeeksOrDays = (value) => {
    // @ts-ignore
    const inDays = Math.round((value - new Date()) / (24 * 60 * 60 * 1000))
    // @ts-ignore
    const inWeeks = Math.round((value - new Date()) / (7 * 24 * 60 * 60 * 1000))

    if (inDays <= 1) {
      return `${inDays} ${i18n.global.t('day')}`
    }

    if (inDays < 10) {
      return `${inDays} ${i18n.global.t('days')}`
    }

    return `${inWeeks} ${i18n.global.t('weeks')}`
  }

  const randomDate = (start, end, startHour, endHour) => {
    const date = new Date(+start + Math.random() * (end - start))
    const hour = (startHour + Math.random() * (endHour - startHour)) | 0
    date.setHours(hour)

    return date
  }

  const formatMillisecondsToMMSS = (duration) => {
    const d = new Date(duration)
    return `${String(d.getMinutes()).padStart(2, '0')}:${String(
      d.getSeconds()
    ).padStart(2, '0')}`
  }

  const formatSecondsToTime = (duration) => {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600)
    const mins = ~~((duration % 3600) / 60)
    const secs = ~~duration % 60

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = ''

    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
    }

    ret += '' + mins + ':' + (secs < 10 ? '0' : '')
    ret += '' + secs

    return ret
  }

  const formatSeconds = (n) => {
    const d = new Date(n * 1000)
    return `${String(d.getMinutes()).padStart(2, '0')}:${String(
      d.getSeconds()
    ).padStart(2, '0')}`
  }

  function formatDateToShortString(date: any) {
    if (date === null) return null

    if (typeof date === 'string') {
      date = new Date(date)
    }

    const month = (1 + date.getMonth()).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${day}/${month}/${date.getFullYear()}`
  }

  return {
    dateDiffInDays,
    humanizeDifferenceFromDateToNow,
    durationFromOrDateIfTooFar,
    formatDate,
    randomDate,
    formatSecondsToTime,
    formatMillisecondsToMMSS,
    formatSeconds,
    formatDateToShortString,
    timestampToDate,
    daysBeforeReset,
    durationToNowWeeksOrDays,
  }
}
