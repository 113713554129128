import { useApiRepositories } from '@/composables/api/useApiRepositories'
import { ref, watch } from 'vue'
import { useApiJsonValidator } from '@/composables/useApiJsonValidator'
import modelUsageValidator from '@/jsonValidator/modelUsageValidator.json'
import { useBuckets } from '@/composables/api/useBuckets'
import { useLocale } from '~/composables/useLocale'
const processing = ref(false)
const model = ref(null)
const openTestModal = ref(false)
const resultsStore = ref(null)
const loadingModel = ref(false)
export const useModel = () => {
  const { modelsRepository, modelsExecutableRepository } = useApiRepositories()
  const { validateData } = useApiJsonValidator()
  const { closeSelectorModal } = useBuckets()

  const setResults = (value) => {
    resultsStore.value = value
  }

  const clearResults = () => {
    resultsStore.value = null
  }

  const clearModel = () => {
    model.value = null
  }

  const setIsProcessing = () => {
    openTestModal.value = true
    processing.value = true
  }

  const processTerminated = () => {
    processing.value = false
    openTestModal.value = false
  }

  const fetchModel = async (slug: string) => {
    try {
      clearModel()
      loadingModel.value = true
      const result = (await modelsRepository.show(slug))?.data
      if (result) {
        const isValidData = validateData(modelUsageValidator, result)
        if (isValidData) model.value = result
      }
      loadingModel.value = false
    } catch (error) {
      console.log('unable to fetch model', error)
    }
  }
  const executeModel = async (slug, payload) => {
    if (resultsStore.value) {
      clearResults()
    }
    closeSelectorModal()
    setIsProcessing()
    try {
      const result =
        //@ts-ignore
        await modelsExecutableRepository.additionalFunctions.execute(
          slug,
          payload
        )
      if (result) {
        setResults(result.data.data)
        processTerminated()
      }
    } catch (error) {
      processTerminated()
      console.error('unable to execute model', error)
    }
  }
  const { locale } = useLocale()

  watch(locale, () => {
    clearResults()
    clearModel()
  })

  return {
    fetchModel,
    model,
    executeModel,
    processing,
    openTestModal,
    resultsStore,
    clearResults,
    processTerminated,
    setIsProcessing,
    clearModel,
    loadingModel,
  }
}
