import { useRepositoryApi } from '@/composables/api/useRepositoryApi'
import { useXsrfToken } from '@/composables/api/useXsrfToken'
import { serialize } from 'object-to-formdata'
import { useAxios } from '@/composables/api/useAxios'
const { axios } = useAxios()

export const useExecutableModelsRepository = () => {
  const resource = 'phedone-models'

  return useRepositoryApi(resource, null, {
    execute: async (slug: string, payload) => {
      const payloadKeys = Object.keys(payload)

      const { globalToken } = useXsrfToken()
      const shouldTransformToFormData =
        payloadKeys.includes('file') || payloadKeys.includes('files')

      const url = `/api/${resource}/${slug}`

      if (shouldTransformToFormData) {
        const formData = serialize(payload, {
          allowEmptyArrays: true,
        })

        return axios.post(url, formData, {
          withCredentials: true,
          headers: {
            'X-XSRF-TOKEN': globalToken.value,
            'Content-Type': 'multipart/form-data',
          },
        })
      } else {
        return axios.post(url, payload, {
          withCredentials: true,
          headers: {
            'X-XSRF-TOKEN': globalToken.value,
            'XSRF-TOKEN': globalToken.value,
          },
        })
      }
    },
  })
}
