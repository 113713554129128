import { useAxios } from '@/composables/api/useAxios'
import { useXsrfToken } from '@/composables/api/useXsrfToken'

const { axios } = useAxios()

export const useOAuthProviderRepository = () => {
  const { getFreshToken } = useXsrfToken()

  const getRedirectionUrl = async (providerSlug: string) => {
    return (await axios.get(`/api/oauth/${providerSlug}/redirect`))?.data
  }
  const passCallbackToBackend = async (providerSlug, code) => {
    await getFreshToken()

    return (
      await axios.get(`/api/oauth/${providerSlug}/callback`, {
        params: {
          code,
        },
      })
    )?.data
  }
  return { getRedirectionUrl, passCallbackToBackend }
}
