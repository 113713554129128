import {
  CalendarIcon,
  CodeBracketIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  LinkIcon,
  PencilSquareIcon,
  PhotoIcon,
  TableCellsIcon,
  VideoCameraIcon,
  ViewColumnsIcon,
  Bars4Icon,
  SpeakerWaveIcon,
  TagIcon,
  FolderIcon,
  ClockIcon,
  CommandLineIcon,
  PresentationChartBarIcon,
  RectangleGroupIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  CheckCircleIcon,
} from '@heroicons/vue/24/solid'
export const useIcons = () => {
  const iconsMapping = {
    CALENDAR: CalendarIcon,
    CODE: CodeBracketIcon,
    DOCUMENT: DocumentTextIcon,
    EXCLAMATION_CIRCLE: ExclamationCircleIcon,
    LINK: LinkIcon,
    PENCIL_SQUARE: PencilSquareIcon,
    PHOTO: PhotoIcon,
    TABLE_CELLS: TableCellsIcon,
    VIDEO_CAMERA: VideoCameraIcon,
    VIEW_COLUMNS: ViewColumnsIcon,
    BARS_4: Bars4Icon,
    SPEAKER: SpeakerWaveIcon,
    TAG: TagIcon,
    FOLDER: FolderIcon,
    CLOCK: ClockIcon,
    COMMAND: CommandLineIcon,
    CHART: PresentationChartBarIcon,
    RECTANGlE_GROUP: RectangleGroupIcon,
    CIRCLE_STACK: CircleStackIcon,
    COG: Cog6ToothIcon,
    CHECK: CheckCircleIcon,
  }

  return { iconsMapping }
}
