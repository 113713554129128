import { computed, ref } from 'vue'
import { AlertType } from '@/enums/alertType'
import Alert from '@/types/Alert'

const globalAlerts = ref<Alert[]>([])

export const useAlerts = () => {
  const alerts = computed(() => globalAlerts.value)

  const addAlert = (message: string, type: AlertType) => {
    globalAlerts.value.push(new Alert(message, type))
  }

  const deleteAlert = (index: number) => {
    if (index >= 0 && index < globalAlerts.value.length) {
      globalAlerts.value.splice(index, 1)
    }
  }
  const clearAlerts = (alertType: AlertType | null) => {
    globalAlerts.value = alertType
      ? globalAlerts.value.filter((a) => a.type !== AlertType.Danger)
      : []
  }

  const removeDuplicateAlerts = () => {
    const seenMessages = new Set()
    globalAlerts.value = globalAlerts.value.filter((alert) => {
      if (seenMessages.has(alert.message)) {
        return false
      } else {
        seenMessages.add(alert.message)
        return true
      }
    })
  }

  const removeAlertAfterDelay = (index: number, delay: number = 5000) => {
    setTimeout(() => {
      deleteAlert(index)
    }, delay)
  }

  return {
    alerts,
    addAlert,
    deleteAlert,
    clearAlerts,
    removeDuplicateAlerts,
    removeAlertAfterDelay,
  }
}
