import Cookies from 'js-cookie'

export const useCookies = () => {
  const clearAllCookies = () => {
    const allCookies = Cookies.get()
    for (let cookieName in allCookies) {
      Cookies.remove(cookieName)
    }
  }

  const setCookie = (cookieName: string, cookieValue: string) => {
    Cookies.set(cookieName, cookieValue)
  }

  return {
    clearAllCookies,
    setCookie,
  }
}
