import { computed, ref } from 'vue'
import { useApiRepositories } from '@/composables/api/useApiRepositories'
import { useApiJsonValidator } from '@/composables/useApiJsonValidator'
import modelsValidator from '@/jsonValidator/modelsValidator.json'
import { Model } from '@/interfaces/ModelInterface'
import { DocumentTextIcon } from '@heroicons/vue/24/outline'
import { useIcons } from '~/composables/icons/useIcons'

const models = ref<Array<Model>>(null)
const loadingModels = ref<boolean>(false)
export const useModels = () => {
  const { modelsRepository } = useApiRepositories()
  const { validateData } = useApiJsonValidator()

  const clearModels = () => {
    models.value = null
  }

  const getModels = async () => {
    try {
      if (models.value) {
        return
      }
      loadingModels.value = true
      const result = (await modelsRepository.index())?.data
      if (result) {
        const isValidData = validateData(modelsValidator, result)
        if (isValidData) {
          models.value = result
          loadingModels.value = false
        }
      }
    } catch (error) {
      console.log('unable to get models', error)
    }
  }
  const { iconsMapping } = useIcons()
  const formattedModelsForSearchPalette = computed(() => {
    if (models.value && models.value.length > 0) {
      return models.value
        .map((model) => ({
          title: model.title,
          description: model.description,
          category: model.category ?? null,
          slug: model.slug,
          color: model.color ? `#${model.color}` : `#9F70FD`,
          icon: model.icon ? iconsMapping[model.icon] : DocumentTextIcon,
        }))
        .sort((a, b) => {
          //@ts-ignore
          if (a.category === 'voice' && b.category !== 'voice') return -1
          //@ts-ignore
          if (a.category !== 'voice' && b.category === 'voice') return 1
          return a.title.localeCompare(b.title)
        })
    }
    return []
  })

  const refreshModels = () => {
    clearModels()
  }

  return {
    getModels,
    models,
    loadingModels,
    refreshModels,
    formattedModelsForSearchPalette,
  }
}
